<template>
	<section v-show="viewShow">
		<PCHome v-if="isPc"></PCHome>
		<MHome v-else></MHome>
	</section>
</template>


<script> 
import { mapState } from 'vuex';  
import PCHome from '@/components/index/pc_index/pc_index'
import MHome from '@/components/index/h5_index/h5_index'

export default {
	components:{},
	name: 'index',
	data() {
		return { 
			showF:false
		};
	},
	components: {
		PCHome,MHome
	},
	watch: { 
		
	}, 
	created() { 
	},
	mounted() {
		
	},
	computed: {
		isPc() { 
		  return this.$store.state.isPc;
		},
		viewShow(){
		  return this.$store.state.viewShow;
		}
	},
	computed: {
	  ...mapState(['isPc','viewShow'])
	},
};
</script>

<style lang="scss" scoped="scoped"> 
</style>
