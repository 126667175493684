<template>
	<div class="wrapper" >
		<section class="wrapper_top">
			<div class="nav_top">
				<div class="nav_top_uls">
					<div class="nav_top_list" @click="ReservationInquiry">預約查詢</div>
					<div class="nav_top_list" @click="cjQuery">成績/證書查詢</div>
				</div>
			</div>
			
			
			<div class="logo"><img :src="require('@/assets/image/logo.png')" alt="" /></div>
			<div class="title">
				<!-- 職業普通話
				<br />
				水平測試 -->
				<img :src="require('@/assets/image/title.png')" alt="" />
			</div>

			<div class="list">
				<span>快速檢測</span>
				<span>普通話水平</span>
			</div>

			<div class="list2">中文路·成功路</div>
		</section>
		<section class="wrapper_centent">
			<div class="uls">
				<div class="lis">
					<div class="lis_title">考試介紹</div>
					<div class="lis_p">
						中文路職業普通話水平測試是對應試人運用普通話的規範程度、熟練程度的口語考試。考試形式為口試，評分形式為AI機器判分。本測試系統由中文路語言測試中心獨立開發，使用與國家普通話水平測試系統同步語音評測科技，短時間即可出分、讓用戶瞭解自己真實的普通話水準。
					</div>
				</div>
			</div>

			<div class="uls">
				<div class="lis">
					<div class="lis_title">等級評定</div>
					<div class="lis_p">
						普通話水平測試等級分為三級六等，即一、二、三級，每個級別再分出優秀及良好兩個等次；一級優秀為最高，三級良好為最低。普通話水平測試不是口才的評定，而是對應試人掌握和運用普通話所達到的規範程度的測查和評定，應試人在運用普通話口語進行表達過程中所表現的語音、詞彙、語法規範程度，是評定其所達到的水平等級的重要依據。
					</div>
					<div class="lis_table">
						<ul>
							<li>等級</li>
							<li>分數</li>
						</ul>
						<ul class="ul_hr">
							<li>一級優秀</li>
							<li>95-100</li>
						</ul>
						<ul class="ul_hr">
							<li>一級良好</li>
							<li>90-94.99</li>
						</ul>
						<ul class="ul_hr">
							<li>二級優秀</li>
							<li> 85-89.99</li>
						</ul>
						<ul class="ul_hr">
							<li>二級良好</li>
							<li>80-84.99</li>
						</ul>
						<ul class="ul_hr">
							<li>三級優秀</li>
							<li>70-79.99</li>
						</ul>
						<ul class="ul_hr">
							<li>三級良好</li>
							<li>60-69.99</li>
						</ul>
						<ul>
							<li>不入等級</li>
							<li>60以下</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="uls">
				<div class="lis">
					<div class="lis_title">考試地點</div>
					<div class="lis_p">新界沙田石門京瑞廣場2期5樓J室</div>
				</div>
			</div>

			<div class="uls">
				<div class="lis">
					<div class="lis_title">注意事項</div>
					<div class="lis_p">
						1、預約時需提供可驗證的姓名、電話、身份證號等個人資料。<br />
						2、考試時務必攜帶與報名時一致的身份證件，以便現場人員核對。<br />
						3、報名後請依照約定時間到場考試，身份核驗不一致時無法考試。<br />
					</div>
				</div>
			</div>

			<!-- <div class="uls">
				<div class="lis">
					<div class="lis_title">考試費用</div>
					<div class="lis_p">xxx元</div>
				</div>
			</div> -->
		</section>

		<section class="wrapper_Bottom">
			<div class="uls">
				<!-- <div class="lis" @click="ReservationInquiry">預約查詢</div> -->
				<div class="lis" @click="ReservationSkip">立即預約考試</div>
			</div>
		</section>
	</div>
</template>

<script>
import IndexMixin from '@/mixin/index_mixin';
export default {
	components: {},
	name: 'h5_index',
	data() {
		return {
			mountedShow:false
		};
	},
	watch: {},
	mixins: [IndexMixin],
	created() {
		this.$nextTick(()=>{
			this.mountedShow = true;
		})
	},
	mounted() { 	
	},
	computed: {},
	methods: {}
};
</script>

<style lang="scss" scoped="scoped">
@import '@/assets/css/index.scss';


.wrapper {
	width: 100%;
	// height: 100%;
	position: relative;
	// font-size: px2rem(28);
	overflow-x: hidden;
	// background: linear-gradient(309deg, #132bb0 0%, #1934cb 100%);
	background: url('~@/assets/image/homebeij.jpg');
	background-size: 100%;
	padding: px2rem(60) px2rem(32);
	box-sizing: border-box;

	.wrapper_top {
		width: 100%;
		
		.nav_top{
			width: 100%;
			height: px2rem(96);
			z-index: 9999999999999;
			
			.nav_top_uls{
				width: 100%;
				height: px2rem(96);
				padding: px2rem(24) px2rem(32);
				box-sizing: border-box;
				position: fixed;
				top: 0;
				left:0;
				z-index: 99999999;
				display: flex;
				align-items: center;
				background-color: rgb(39,74,196);
				
				.nav_top_list{
					font-size: px2rem(36);
					font-family: PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					color: #FFFFFF;
					margin-right: px2rem(72);
				}
			}
			
		}

		.logo {
			width: px2rem(120);
			height: px2rem(120);
			background: #ffffff;
			box-shadow: 0px px2rem(4) px2rem(40) 0px rgba(0, 0, 0, 0.12);
			border-radius: px2rem(32);
			margin: auto;
			margin-top:px2rem(104) ;
			text-align: center;
			line-height: px2rem(120);
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: px2rem(72);
				height: px2rem(90);
				vertical-align: middle;
			}
		}

		.title {
			width: px2rem(474);
			font-size: px2rem(96);
			font-family: Alibaba PuHuiTi-Heavy;
			font-weight: 800;
			color: #ffffff;
			line-height: px2rem(132);
			text-align: center;
			margin:auto;
			margin-top: px2rem(38);
			text-shadow: 0px px2rem(8) px2rem(4) rgba(8, 131, 243, 0.4);
			letter-spacing: px2rem(1);
			
			img{
				width: 100%;
			}
		}

		.list {
			width: px2rem(476);
			height: px2rem(86);
			line-height: px2rem(86);
			background: #2841cf;
			border-radius: px2rem(62);
			border: px2rem(4) solid #ffffff;
			margin: auto;
			margin-top: px2rem(28);
			font-size: px2rem(40);//藍湖40
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			text-align: center;
			letter-spacing: px2rem(5);

			span:nth-child(1) {
				color: #f3db00; 
			}
			span:nth-child(2) {
				color: #fff; 
			}
		}

		.list2 {
			width: 100%;
			text-align: center;
			margin-top: px2rem(28);
			font-size: px2rem(28);
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #ffffff;
			letter-spacing: px2rem(18);
		}
	}

	.wrapper_centent {
		width: 100%;
		margin-top: px2rem(156);

		.uls {
			width: 100%;
			margin-bottom: px2rem(124);

			.lis {
				position: relative;
				width: 100%;
				// background: #f6f8ff;
				background:  #f6f8ff url('~@/assets/image/home_listbg.png') no-repeat;  
				background-size: 100%;
				border-radius: px2rem(32);
				box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(28, 98, 216, 0.4);
				padding: px2rem(74) px2rem(32) px2rem(32) px2rem(32);
				box-sizing: border-box;

				.lis_title {
					width: px2rem(280);
					height: px2rem(86);
					line-height: px2rem(86);
					margin: auto;
					margin-top: -20%;
					margin-bottom: px2rem(32);
					background: #2841cf;
					border-radius: px2rem(62);
					border: px2rem(4) solid #ffffff;
					font-size: px2rem(40);//藍湖40
					font-family: PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					text-align: center;
					color: #fff;
					box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(28,98,216,0.4);
				}

				.lis_p {
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: px2rem(52);
					text-align: left;
				}

				.lis_table {
					width: 100%;
					background-color: #fff;
					border-radius: px2rem(8) px2rem(8) px2rem(8) px2rem(8);
					border: px2rem(2) solid #2841cf;
					margin-top: px2rem(32);
					overflow: hidden;

					ul {
						width: 100%;
						height: px2rem(92);
						line-height: px2rem(92);
						display: flex;

						li {
							width: 50%;
							height: 100%;
							text-align: center;
							font-size: px2rem(28);
						}

						li:nth-child(1) {
							font-family: PingFang SC-Semibold, PingFang SC;
							color: #333333;
							font-weight: 600;
						}

						li:nth-child(2) {
							font-family: PingFang SC-Semibold, PingFang SC;
							color: #e80202;
							font-weight: 600;
						}
					}

					ul:nth-child(1) {
						background: #e0e5ff;
						font-family: PingFang SC-Regular, PingFang SC;

						li {
							color: #333333;
							font-weight: 400;
						}
					}

					.ul_hr {
						position: relative;
					}

					.ul_hr::after {
						content: ' ';
						width: 90%;
						height: px2rem(1);
						background: #b9c4ff;
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0%;
						margin: auto;
					}
				}
			}
		}
	}

	.wrapper_Bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		min-height: px2rem(130);
		padding: px2rem(16) px2rem(32);
		// padding-bottom: env(safe-area-inset-bottom);
		box-sizing: border-box;
		background: rgba(255, 255, 255, 0.6); 
		backdrop-filter: blur(#{px2rem(32)});

		.uls {
			width: 100%;
			height: px2rem(96);
			// background: #122AB0;
			box-sizing: border-box;
			// border: px2rem(4) solid #FFFFFF;
			display: flex;
			border-radius: 50px;
			overflow: hidden;
			box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(18,42,176,0.3);

			// .lis:nth-child(1) {
			// 	width: px2rem(256);
			// 	// height: 100%;
			// 	text-align: center;
			// 	font-size: px2rem(40);
			// 	font-family: PingFang SC-Semibold, PingFang SC;
			// 	font-weight: 600;
			// 	color: #ffffff;
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// 	background: #ff9900;
			// 	border-left: px2rem(4) solid #fff;
			// 	border-top: px2rem(4) solid #fff;
			// 	border-bottom: px2rem(4) solid #fff;
			// 	border-radius: px2rem(50) 0px 0px px2rem(50);
			// }

			.lis:nth-child(1) {
				// width: calc(100% - #{px2rem(256)});
				width: 100%;
				text-align: center;
				font-size: px2rem(40);
				font-family: PingFang SC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ffffff;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #122ab0;
				border-right: px2rem(4) solid #fff;
				border-top: px2rem(4) solid #fff;
				border-bottom: px2rem(4) solid #fff;
				border-left: px2rem(4) solid #fff;
				// border-radius: 0px px2rem(50) px2rem(50) 0px;
				border-radius: px2rem(50);
			}
		}
	}
}
</style>
