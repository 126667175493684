export default {
  data() {
    return {
		
    }
  },
  watch: {
  
  },
  created() {
	  
  },
  computed: {

  },
  methods: {
	ReservationSkip(){ 
		this.$router.push('/reservationForm')
	},
	ReservationInquiry(){
		this.$router.push('/reservationInquire')
	},
	cjQuery(){
		this.$router.push('/query')
	}
  }
}
