<template>
  <div class="activity_page">
    <div class="head">
      <div class="head_left">
        <div @click="handleQuery('pre')">預約查詢</div>
        <div @click="handleQuery('result')">成績/證書查詢</div>
      </div>
    </div>
    <div class="banner">
      <div class="banner_text">
        <div class="chahua"></div>
        <div class="logo">
          <img src="@/assets/image/adultTest/logo.png" alt="" />
        </div>
        <div class="theme">
          <img src="@/assets/image/adultTest/text.png" alt="" />
        </div>
        <div class="text_1">
          <span style="color: #f3db00">快速檢測</span>
          <span>普通話水平</span>
        </div>
        <div class="text_2">
          <div>中文路</div>
          <div></div>
          <div>成功路</div>
        </div>
      </div>
      <div class="immediately" @click="handlePre()">
        <img
          src="@/assets/image/adultTest/immediately_button.png"
          alt=""
        />
      </div>
    </div>
    <div class="content_area">
      <div class="card_1">
        <div class="background_img">
          <span
            >中文路職業普通話水平測試是對應試人運用普通話的規範程度、熟練程度的口語考試。考試形式為口試，評分形式為AI機器判分。本測試系統由中文路語言測試中心獨立開發，使用與國家普通話水平測試系統同步語音評測科技，短時間即可出分、讓用戶瞭解自己真實的普通話水準。</span
          >
          <div class="title"><span>考試介紹</span></div>
        </div>
      </div>
      <div class="card_2">
        <div class="background_img">
        </div>
        <div class="content">
            <span
              >普通話水平測試等級分為三級六等，即一、二、三級，每個級別再分出優秀及良好兩個等次；一級優秀為最高，三級良好為最低。普通話水平測試不是口才的評定，而是對應試人掌握和運用普通話所達到的規範程度的測查和評定，應試人在運用普通話口語進行表達過程中所表現的語音、詞彙、語法規範程度，是評定其所達到的水平等級的重要依據。</span
            >
            <div class="table_area">
              <div style="background: #e0e5ff">
                <table class="table_thead">
                  <tbody>
                    <tr>
                      <td>等級</td>
                      <td>分數</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table class="table">
                <tbody>
                  <tr v-for="(item, index) in gradeList" :key="index">
                    <td>{{ item.grade }}</td>
                    <td style="color: red">{{ item.score }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="title"><span>等級評定</span></div>
          </div>
      </div>
      <div class="card_3">
        <div class="background_img">
          <span>新界沙田石門京瑞廣場2期5樓J室</span>
          <div class="title"><span>考試地點</span></div>
        </div>
      </div>
      <div class="card_4">
        <div class="background_img">
          <div class="text">
            <p>1. 預約時需提供可驗證的姓名、電話、身份證號等個人資料。</p>
            <p>2. 考試時務必攜帶與報名時一致的身份證件，以便現場人員核對。</p>
            <p>3. 報名後請依照約定時間到場考試，身份核驗不一致時無法考試。</p>
          </div>
          <div class="title"><span>注意事項</span></div>
        </div>
      </div>
      <!-- <div class="card_5">
        <div class="background_img">
          <span>xxxx元</span>
          <div class="title"><span>考試費用</span></div>
        </div>
      </div> -->
    </div>
    <div class="immediately_group" v-if="scroll >= 400" @click="handlePre()">
      <img src="@/assets/image/adultTest/immediately_group.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'pc_index',
  data() {
    return {
      gradeList: [
        { grade: '一級優秀', score: '95-100' },
        { grade: '一級良好', score: '90-94.99' },
        { grade: '二級優秀', score: '85-89.99' },
        { grade: '二級良好', score: '80-84.99' },
        { grade: '三級優秀', score: '70-79.99' },
        { grade: '三級良好', score: '60-69.99' },
        { grade: '不入等級', score: '60以下' }
      ],
      scroll: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getScroll)
  },
  methods: {
    handleQuery(type) {
      // pre:預約查詢  result:成績查詢
      this.$router.push({ name: 'testLogIn', query: { type } })
    },
    handlePre() {
      this.$router.push({ name: 'informationFilling', params: { type: 'pre' } })
    },
    getScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop
      // console.log(this.scroll)
    }
  }
}
</script>

<style lang="scss" scoped>
.activity_page {
  width: 100%;
  background: #f1f4fe;
  .head {
    width: 100%;
    height: 80px;
    background: #122ab0;
    position: fixed;
    top: 0;
    z-index: 9999;
    .head_left {
      height: 100%;
      font-size: 20px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 23px;
      display: flex;
      justify-content: flex-left;
      align-items: center;
      margin-left: 245px;
      div {
        margin-right: 56px;
      }
      div:hover {
        cursor: pointer;
        opacity: 0.9;
        transition: background-color 0.3s ease;
      }
    }
  }
  .banner {
    height: 600px;
    width: 100%;
    margin-top: 78px;
    background: url('~@/assets/image/adultTest/adultTest_banner.jpg')
      no-repeat center;
    background-size: 100% 600px;
    // background-position: center center;
    position: relative;
    .banner_text {
      padding-top: 86px;
      .logo {
        margin: 0 auto;
        display: table;
        background: #ffffff;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        padding: 10px 16px;
        box-sizing: border-box;
        img {
          width: 48px;
          height: 60px;
        }
      }
      .theme {
        position: relative;
        margin: 34px auto 34px auto;
        img {
          width: 571px;
        }
      }
      .text_1 {
        margin: 0 auto;
        width: 279px;
        height: 50px;
        box-shadow: 0px 4px 4px 0px rgba(28, 98, 216, 0.4);
        border-radius: 31px;
        border: 2px solid #ffffff;
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fff;
        line-height: 50px;
        letter-spacing: 1px;
      }
      .text_2 {
        margin: 24px auto 0 auto;
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 19px;
        letter-spacing: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        div:nth-child(1) {
          margin-left: 20px;
        }
        div:nth-child(2) {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background: #fff;
          margin-right: 20px;
          box-sizing: border-box;
        }
      }
      .chahua {
        width: 438px;
        height: 460px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -201px 0 0 -209px;
        background: url('~@/assets/image/adultTest/chahua.png') no-repeat
          center;
        background-size: 438px 460px;
      }
    }
    .immediately {
      position: absolute;
      left: 50%;
      margin-left: -95px;
      bottom: 88px;
      cursor: pointer;
      width: 190px;
      height: 69px;
      animation-iteration-count: infinite;
      animation-duration: 2s;
      animation-name: mymove;
      img{
        width: 100%;
      }
    }
  }
  .content_area {
    padding-bottom: 149px;
    .card_1 {
      margin: 89px auto 0 auto;
      width: 950px;
      // background: #f4f6ff;
      background: linear-gradient(
        180deg,
        #f6f8ff 0%,
        rgba(247, 249, 255, 0) 100%
      );
      box-shadow: 0px 4px 20px 0px rgba(40, 65, 207, 0.08);
      border-radius: 16px;
      .background_img {
        padding: 45px 24px 20px 24px;
        text-align: left;
        position: relative;
        background: url('~@/assets/image/adultTest/background_01.png')
          no-repeat;
        background-size: 282px 121px;
        background-position: right 0px top;
        span {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
        }
        .title {
          width: 140px;
          height: 44px;
          background: #2841cf;
          box-shadow: 0px 4px 4px 0px rgba(28, 98, 216, 0.4);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          position: absolute;
          top: -24px;
          left: 50%;
          margin-left: -70px;
          text-align: center;
          box-sizing: border-box;
          span {
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 42px;
          }
        }
      }
    }
    .card_2 {
      margin: 89px auto 0 auto;
      width: 950px;
      background: linear-gradient(
        225deg,
        #e7eefa 0%,
        rgba(247, 249, 255, 0) 24%
      );
      // background: #f4f6ff;
      box-shadow: 0px 4px 20px 0px rgba(40, 65, 207, 0.08);
      border-radius: 16px;
      position: relative;
      .background_img {
        position: absolute;
        right: 0;
        top: 0;
        width: 282px;
        height: 254px;
        background: url('~@/assets/image/adultTest/background_02.png')
          no-repeat;
        background-size: 282px 254px;
        // background-position: right 0px top;
        opacity:0.6;

      }
      .content {
          text-align: left;
          position: relative;
          padding: 45px 24px 32px 24px;
          span {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
          }
          .table_area {
            margin: 18px auto 0 auto;
            width: 481px;
            border-radius: 4px;
            border: 1px solid #2841cf;
            background: #fff;
            overflow: hidden;
            .table_thead {
              text-align: center;
              width: 90%;
              margin: 0 auto;
              tr {
                height: 46px;
                color: #333333;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                line-height: 46px;
              }
              td {
                width: 50%;
              }
            }
            .table {
              width: 90%;
              text-align: center;
              margin: 0 auto;
              tr {
                height: 46px;
                color: #333333;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                line-height: 46px;
                border-bottom: 1px solid rgba(185, 196, 255, 1);
              }
              tr:last-child {
                border: none;
              }
              td {
                font-weight: 600;
                width: 50%;
              }
            }
          }
          .title {
            width: 140px;
            height: 44px;
            background: #2841cf;
            box-shadow: 0px 4px 4px 0px rgba(28, 98, 216, 0.4);
            border-radius: 31px 31px 31px 31px;
            border: 2px solid #ffffff;
            position: absolute;
            top: -24px;
            left: 50%;
            margin-left: -70px;
            text-align: center;
            box-sizing: border-box;
            span {
              font-size: 20px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              line-height: 42px;
            }
          }
        }
    }
    .card_3 {
      margin: 89px auto 0 auto;
      width: 950px;
      // background: #f4f6ff;
      background: linear-gradient(
        180deg,
        #f6f8ff 0%,
        rgba(247, 249, 255, 0) 100%
      );
      box-shadow: 0px 4px 20px 0px rgba(40, 65, 207, 0.08);
      border-radius: 16px;
      .background_img {
        padding: 45px 0 24px 0;
        text-align: center;
        position: relative;
        background: url('~@/assets/image/adultTest/background_03.png')
          no-repeat;
        background-size: 282px 96px;
        background-position: right 0px top;
        span {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 26px;
        }
        .title {
          width: 140px;
          height: 44px;
          background: #2841cf;
          box-shadow: 0px 4px 4px 0px rgba(28, 98, 216, 0.4);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          position: absolute;
          top: -24px;
          left: 50%;
          margin-left: -70px;
          text-align: center;
          box-sizing: border-box;
          span {
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 42px;
          }
        }
      }
    }
    .card_4 {
      margin: 89px auto 0 auto;
      width: 950px;
      // background: #f4f6ff;
      background: linear-gradient(
        180deg,
        #f6f8ff 0%,
        rgba(247, 249, 255, 0) 100%
      );
      box-shadow: 0px 4px 20px 0px rgba(40, 65, 207, 0.08);
      border-radius: 16px;
      .background_img {
        padding: 45px 0 24px 0;
        text-align: left;
        position: relative;
        background: url('~@/assets/image/adultTest/background_04.png')
          no-repeat;
        background-size: 282px 169px;
        background-position: right 0px top;
        .text {
          margin: 0 auto;
          display: table;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 26px;
        }
        .title {
          width: 140px;
          height: 44px;
          background: #2841cf;
          box-shadow: 0px 4px 4px 0px rgba(28, 98, 216, 0.4);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          position: absolute;
          top: -24px;
          left: 50%;
          margin-left: -70px;
          text-align: center;
          box-sizing: border-box;
          span {
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 42px;
          }
        }
      }
    }
    .card_5 {
      margin: 89px auto 0 auto;
      width: 950px;
      // background: #f4f6ff;
      background: linear-gradient(
        180deg,
        #f6f8ff 0%,
        rgba(247, 249, 255, 0) 100%
      );
      box-shadow: 0px 4px 20px 0px rgba(40, 65, 207, 0.08);
      border-radius: 16px;
      box-sizing: border-box;
      .background_img {
        padding: 45px 0 24px 0;
        text-align: center;
        position: relative;
        background: url('~@/assets/image/adultTest/background_05.png')
          no-repeat;
        background-size: 282px 95px;
        background-position: right 0px top;
        span {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 26px;
        }
        .title {
          width: 140px;
          height: 44px;
          background: #2841cf;
          box-shadow: 0px 4px 4px 0px rgba(28, 98, 216, 0.4);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          position: absolute;
          top: -24px;
          left: 50%;
          margin-left: -70px;
          text-align: center;
          box-sizing: border-box;
          span {
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 42px;
          }
        }
      }
    }
  }
  .immediately_group {
    position: fixed;
    bottom: 15px;
    right: 32px;
    width: 140px;
    height: 148px;
    cursor: pointer;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-name: mymove;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@keyframes mymove {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
</style>
